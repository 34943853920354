import { httpClient } from "@/libs/http";
import { checkUserLoginPermission, checkUserLoginModule } from "@/libs/helpers";

export default async function eMarket({ next, router, to }) {
    const sct = localStorage.getItem('_sct')
    if (!sct) {
        return router.push({ name: 'Login' });
    }
    try {
        if (!window.me) {
            const { data } = await httpClient.post('/me')
            window.me = { ...data }
        }
        const userPermission = window.me && window.me.permissions || []
        const checkPermission = checkUserLoginPermission(userPermission, to.meta.permissions)

        const userModule = window.me && window.me.modules || []
        const checkModule = checkUserLoginModule(userModule, to.meta.modules)

        if (checkPermission && checkModule) {
            return next();
        }
        return next('/403');
    } catch (e) {
        console.error('getMe->ERROR:', e)
        return next('/403');
    }
}
